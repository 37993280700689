import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import { Map, Widget } from '../..'
import { Pin } from '../../map/map'
import { useActiveLocations, useProfile } from '../../../../hooks'
import { isEmployeeRole } from '../../../../common/utils/roles'
import { NoResults } from '../../../common'
import { Paths } from '../../../../constants/structure'
import { Location } from '../../../../types'

import * as styles from './locationsWidget.module.scss'

interface Props {
   isLoading: boolean
   locations: Location[]
}

const LocationsWidget: React.FC<Props> = ({ isLoading, locations }) => {
   const { profile } = useProfile()

   const locationsWithLatLng =
      locations.filter(
         ({ Latitude, Longitude }) => !!Latitude && !!Longitude
      ) || []
   const latLngs = locationsWithLatLng.map(({ Latitude, Longitude }) => ({
      lat: Latitude,
      lng: Longitude,
   }))

   const showMap = !isLoading && !!locationsWithLatLng.length
   const showNoResults = !isLoading && !locationsWithLatLng.length
   const isEmployeeUser = isEmployeeRole(profile.Roles || [])

   return (
      <>
         {showMap && (
            <div className={styles.content}>
               <Map markers={latLngs}>
                  {locationsWithLatLng.map((location, index) => (
                     <Pin
                        color={!!location?.Warehouse ? '#6eb250' : '#b21a1f'}
                        key={`${location?.Description}_${location?.Latitude}_${index}`}
                        lat={location?.Latitude}
                        lng={location?.Longitude}
                        title={location?.Description}
                     />
                  ))}
               </Map>
            </div>
         )}

         {showNoResults && (
            <NoResults>
               {isEmployeeUser ? (
                  <>
                     <div>Your company hasn't added any locations yet.</div>
                     <div>
                        Please contact your company admin for more details.
                     </div>
                  </>
               ) : (
                  <>
                     <div>
                        You haven't added any locations with coordinates yet.
                     </div>
                     <div>
                        <Link to={Paths.LOCATIONS}>Add a location</Link>
                     </div>
                  </>
               )}
            </NoResults>
         )}
      </>
   )
}

export default LocationsWidget
