import React, { useEffect, useState } from 'react'
import * as styles from './dashboardSettingsWizard.module.scss'
import { useSettingsWizard } from '../../hooks'
import { WizardViews } from './wizardEnums'
import { Row, Col } from 'react-grid-system'
import { Modal, SnackBar } from '../common'
import { WelcomeView } from './welcomeView'
import { SettingsContent } from './settingsContent'
import { CompleteSettingsView } from './completeSettingsView'
import { SettingsImage } from './settingsImage'
import { useMediaQuery } from 'react-responsive'
import { ProgressIndicator } from '../common/progressIndicator/progressIndicator'
import { Severity } from '../../types'
import * as spinnerStyles from '../../components/common/loadingOverlay/loadingOverlay.module.scss'
import { ImSpinner4 as Spinner } from 'react-icons/im'

interface Props {
   isSettingsWizardOpen: boolean
   updateIsSettingsWizardOpen: (value: boolean) => void
   handleCloseSettingsWizard: () => any
}

export const DashboardSettingsWizard: React.FunctionComponent<Props> = ({
   isSettingsWizardOpen,
   updateIsSettingsWizardOpen,
   handleCloseSettingsWizard,
}) => {
   const {
      updateWizardSettings,
      isWizardSuccess,
      settingsUploading,
      isWizardError,
      error,
   } = useSettingsWizard()
   const isMobileLayout = useMediaQuery({ query: '(max-width: 760px)' })
   const minScreenWidth = useMediaQuery({ query: '(max-width: 575px)' })
   const [currentView, setCurrentView] = useState<WizardViews>(
      WizardViews.WELCOME
   )
   const [currentToggleValue, setCurrentToggleValue] = useState<boolean>(null)
   const [locationTrackingIsEnabled, setLocationTrackingIsEnabled] =
      useState<boolean>(false)
   const [quantityItemsIsEnabled, setQuantityItemsIsEnabled] =
      useState<boolean>(false)
   const [kitItemsIsEnabled, setKitItemsIsEnabled] = useState<boolean>(false)
   const [consumablesIsEnabled, setConsumablesIsEnabled] =
      useState<boolean>(false)
   const [auditsIsEnabled, setAuditsIsEnabled] = useState<boolean>(false)
   const [globalScanToAcceptIsEnabled, setGlobalScanToAcceptIsEnabled] =
      useState<boolean>(false)

   useEffect(() => {
      if (isWizardSuccess) {
         updateIsSettingsWizardOpen(false)
      }
   }, [isWizardSuccess])

   useEffect(() => {
      switch (currentView) {
         case WizardViews.WELCOME:
            setCurrentToggleValue(null)
            break
         case WizardViews.LOCATIONS:
            setCurrentToggleValue(locationTrackingIsEnabled)
            break
         case WizardViews.QUANTITY_ITEMS:
            setCurrentToggleValue(quantityItemsIsEnabled)
            break
         case WizardViews.KIT_ITEMS:
            setCurrentToggleValue(kitItemsIsEnabled)
            break
         case WizardViews.CONSUMABLES:
            setCurrentToggleValue(consumablesIsEnabled)
            break
         case WizardViews.AUDITS:
            setCurrentToggleValue(auditsIsEnabled)
            break
         case WizardViews.SCAN_TO_ACCEPT:
            setCurrentToggleValue(globalScanToAcceptIsEnabled)
            break
      }
   }, [currentView])

   const handleAdvanceWizard = () => {
      if (currentView !== WizardViews.APPLY_SETUP) {
         setCurrentView(currentView + 1)
      }
   }

   const handleBackTrackWizard = () => {
      if (currentView !== WizardViews.WELCOME) {
         setCurrentView(currentView - 1)
      }
   }

   const handleSettingsToggle = () => {
      switch (currentView) {
         case WizardViews.LOCATIONS:
            setLocationTrackingIsEnabled(!locationTrackingIsEnabled)
            setCurrentToggleValue(!locationTrackingIsEnabled)
            break
         case WizardViews.QUANTITY_ITEMS:
            setQuantityItemsIsEnabled(!quantityItemsIsEnabled)
            setCurrentToggleValue(!quantityItemsIsEnabled)
            break
         case WizardViews.KIT_ITEMS:
            setKitItemsIsEnabled(!kitItemsIsEnabled)
            setCurrentToggleValue(!kitItemsIsEnabled)
            break
         case WizardViews.CONSUMABLES:
            setConsumablesIsEnabled(!consumablesIsEnabled)
            setCurrentToggleValue(!consumablesIsEnabled)
            break
         case WizardViews.AUDITS:
            setAuditsIsEnabled(!auditsIsEnabled)
            setCurrentToggleValue(!auditsIsEnabled)
            break
         case WizardViews.SCAN_TO_ACCEPT:
            setGlobalScanToAcceptIsEnabled(!globalScanToAcceptIsEnabled)
            setCurrentToggleValue(!globalScanToAcceptIsEnabled)
            break
      }
   }

   const handleCompleteSetupWizard = () => {
      updateWizardSettings({
         LocationTracking: locationTrackingIsEnabled,
         QuantityItems: quantityItemsIsEnabled,
         KitItems: kitItemsIsEnabled,
         Consumables: consumablesIsEnabled,
         Audits: auditsIsEnabled,
         GlobalScanToAccept: globalScanToAcceptIsEnabled,
      })
   }

   const hideProgressIndicator =
      currentView === WizardViews.WELCOME ||
      currentView === WizardViews.COMPLETE_SETUP
   return (
      <Modal
         isModalVisible={isSettingsWizardOpen}
         closeModal={handleCloseSettingsWizard}
         maxWidth={''}
         marginTop={isMobileLayout ? '2.5rem' : ''}
         zIndex={'999'}
         stickyHeader={true}
         title={'Getting Started'}
         headerColour={'#FFCA05'}
         closeSvgFill={'#000'}
         hideCloseIcon={true}
      >
         <div className={styles.wizardWrapper}>
            {settingsUploading && (
               <div className={styles.loadingWrapper}>
                  <Row>
                     <Col
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        style={{ height: '45rem' }}
                     >
                        <div className={styles.loadingInner}>
                           <h3
                              className={styles.columnHeader}
                              style={{
                                 marginBottom: '5rem',
                                 zIndex: '999',
                                 width: '100%',
                                 display: 'flex',
                                 justifyContent: 'center',
                              }}
                           >
                              Almost there. Customizing your setup now...
                           </h3>
                           <Spinner className={spinnerStyles.icon} size={40} />
                        </div>
                     </Col>
                     <Col
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        style={{ height: '45rem' }}
                     ></Col>
                  </Row>
               </div>
            )}
            {!settingsUploading && (
               <Row>
                  <Col xs={6} sm={6} md={6} lg={6} style={{ height: '45rem' }}>
                     {currentView === WizardViews.WELCOME && (
                        <WelcomeView
                           handleAdvanceWizard={handleAdvanceWizard}
                        />
                     )}
                     {currentView !== WizardViews.WELCOME &&
                        currentView !== WizardViews.COMPLETE_SETUP && (
                           <SettingsContent
                              currentToggleValue={currentToggleValue}
                              handleAdvanceWizard={handleAdvanceWizard}
                              handleBackTrackWizard={handleBackTrackWizard}
                              handleSettingsToggle={handleSettingsToggle}
                              currentView={currentView}
                           />
                        )}
                     {currentView === WizardViews.COMPLETE_SETUP && (
                        <CompleteSettingsView
                           handleBackTrackWizard={handleBackTrackWizard}
                           handleCompleteSetupWizard={handleCompleteSetupWizard}
                        />
                     )}
                     <div
                        className={
                           minScreenWidth
                              ? styles.progressIndicatorWrapperLeft
                              : styles.progressIndicatorWrapperCenter
                        }
                        style={{ opacity: hideProgressIndicator ? 0 : 1 }}
                     >
                        <ProgressIndicator
                           totalViews={6}
                           currentView={
                              hideProgressIndicator ? null : currentView - 1
                           }
                        />
                     </div>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} style={{ height: '45rem' }}>
                     <SettingsImage currentView={currentView} />
                  </Col>
               </Row>
            )}
            {isWizardSuccess ||
               (isWizardError && (
                  <SnackBar
                     message={
                        isWizardSuccess
                           ? 'Your settings have been applied'
                           : `${error} Please backup and restart the wizard`
                     }
                     open={isWizardSuccess || isWizardError}
                     severity={
                        isWizardSuccess ? Severity.SUCCESS : Severity.ERROR
                     }
                     disableAutoClose={false}
                  />
               ))}
         </div>
      </Modal>
   )
}
