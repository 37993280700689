import React, { useCallback, useMemo, useState } from 'react'
import { Col, Row } from 'react-grid-system'

import {
   LocationsWidget,
   TotalsWidget,
   WelcomeWidget,
} from '../../../components/common/dashboard'
import {
   NotificationList,
   PageHeader,
   Seo,
   TabbedWidget,
} from '../../../components/common'
import {
   useActiveLocations,
   useNotificationTotals,
   useProfile,
   useSettings,
} from '../../../hooks'
import { TabbedWidgetProps } from '../../common/tabbedWidget/types'
import { DashboardSettingsWizard } from '../../dashboardSettingsWizard/dashboardSettingsWizard'

import * as styles from './dashboard.module.scss'
import { isAdminRole, isCompanyRole } from '../../../common/utils/roles'
import CountUp from 'react-countup'
import { NotificationTypes } from '../../../types'

const COMPANY_NAME = process.env.APP_COMPANY

const Dashboard = () => {
   const { settings } = useSettings()
   const { profile } = useProfile()
   const { isLoading, list: locations } = useActiveLocations()
   const {
      isLoading: isTotalsLoading,
      refresh,
      totals,
      totalNotifications,
   } = useNotificationTotals()

   const welcomeMapDefaultTab = useMemo(
      () =>
         settings?.EnableLocationTracking && locations?.length
            ? 'map'
            : 'welcome',
      [settings, locations]
   )
   const [selectedWelcomeMapTab, setSelectedWelcomeMapTab] =
      useState<string>(welcomeMapDefaultTab)
   const [selectedNotificationsTab, setSelectedNotificationsTab] =
      useState<string>('actionTasks')
   const [disableCountup, setDisableCountup] = useState<boolean>(false)
   const [isSettingsWizardOpen, setIsSettingsWizardOpen] =
      useState<boolean>(true)

   const title = useMemo(() => `Welcome to ${COMPANY_NAME}`, [COMPANY_NAME])

   const isAdminOrCompany = useMemo(() => {
      const roles = profile?.Roles ?? []
      return isAdminRole(roles) || isCompanyRole(roles)
   }, [profile])

   const onNotificationsUpdated = useCallback(() => refresh(), [])

   const onCountUpEnd = useCallback(
      () => setDisableCountup(true),
      [setDisableCountup]
   )

   const handleCloseSettingsWizard = useCallback(
      () => setIsSettingsWizardOpen(false),
      [setIsSettingsWizardOpen]
   )

   const welcomeMapTabs: TabbedWidgetProps['tabItems'] = useMemo(
      () => [
         {
            id: 'welcome',
            label: 'Welcome',
            content: <WelcomeWidget />,
         },
         ...(settings?.EnableLocationTracking
            ? [
                 {
                    id: 'map',
                    label: 'Map',
                    content: <LocationsWidget {...{ isLoading, locations }} />,
                 },
              ]
            : []),
      ],
      [settings, isLoading, locations]
   )

   const notificationTabsHeading = useMemo(
      () => (
         <span className={styles.totalText}>
            {!disableCountup ? (
               <CountUp
                  start={0}
                  duration={1}
                  useEasing
                  onEnd={onCountUpEnd}
                  end={isTotalsLoading ? 0 : totalNotifications}
               />
            ) : (
               totalNotifications
            )}{' '}
            task{totalNotifications !== 1 ? 's' : ''} require action
         </span>
      ),
      [disableCountup, isTotalsLoading, totalNotifications]
   )

   const renderNotificationTabLabel = useCallback(
      (label: string, id: string) => (
         <>
            <span className={styles.tabLabel}>{label}</span>
            <span className={styles.tabTotal}>
               {!disableCountup ? (
                  <CountUp
                     start={0}
                     duration={1}
                     useEasing
                     onEnd={onCountUpEnd}
                     end={isTotalsLoading ? 0 : totals[id]}
                  />
               ) : (
                  totals[id]
               )}
            </span>
         </>
      ),
      [disableCountup, isTotalsLoading, totals]
   )

   const noticationTabs: TabbedWidgetProps['tabItems'] = useMemo(
      () => [
         {
            id: 'actionTasks',
            label: renderNotificationTabLabel('Actions', 'ActionTasks'),
            content: (
               <NotificationList
                  hideDismiss
                  onUpdate={onNotificationsUpdated}
                  notificationType={NotificationTypes.Action}
                  total={totals.ActionTasks}
               />
            ),
         },
         {
            id: 'reminderTasks',
            label: renderNotificationTabLabel('Reminders', 'ReminderTasks'),
            content: (
               <NotificationList
                  hideAcceptDecline
                  onUpdate={onNotificationsUpdated}
                  notificationType={NotificationTypes.Reminder}
                  total={totals.ReminderTasks}
               />
            ),
         },
         {
            id: 'informationalTasks',
            label: renderNotificationTabLabel('Info', 'InformationalTasks'),
            content: (
               <NotificationList
                  hideAcceptDecline
                  onUpdate={onNotificationsUpdated}
                  notificationType={NotificationTypes.Info}
                  total={totals.InformationalTasks}
               />
            ),
         },
         ...(isAdminOrCompany
            ? [
                 {
                    id: 'globalTasks',
                    label: <span className={styles.tabLabel}>Global</span>,
                    content: (
                       <NotificationList
                          hideAcceptDecline
                          onUpdate={onNotificationsUpdated}
                          notificationType={NotificationTypes.Global}
                          total={totals.GlobalTasks}
                       />
                    ),
                 },
              ]
            : []),
      ],
      [totals, onNotificationsUpdated]
   )

   return (
      <>
         <Seo title="Dashboard" />
         <PageHeader title={title} subtitle={profile.DisplayName} />
         <div className={styles.widgetContainer}>
            <Row gutterWidth={40}>
               <Col xs={12} md={12} lg={12}>
                  <TotalsWidget profile={profile} />
               </Col>
            </Row>
            <Row gutterWidth={40}>
               <Col xs={12} lg={6}>
                  <TabbedWidget
                     className={styles.spacingHeading}
                     selectedTab={selectedWelcomeMapTab}
                     setSelectedTab={setSelectedWelcomeMapTab}
                     tabItems={welcomeMapTabs}
                  />
               </Col>
               <Col xs={12} lg={6} className={styles.widgetFlexed}>
                  <TabbedWidget
                     className={styles.notificationsWidget}
                     selectedTab={selectedNotificationsTab}
                     setSelectedTab={setSelectedNotificationsTab}
                     heading={notificationTabsHeading}
                     isLoading={isTotalsLoading}
                     tabItems={noticationTabs}
                     hideSpinner
                  />
               </Col>
            </Row>
         </div>
         {settings?.WizardStatus === false && (
            <DashboardSettingsWizard
               isSettingsWizardOpen={isSettingsWizardOpen}
               updateIsSettingsWizardOpen={setIsSettingsWizardOpen}
               handleCloseSettingsWizard={handleCloseSettingsWizard}
            />
         )}
      </>
   )
}

export default Dashboard
