import React, { useEffect, useState } from "react";
import * as styles from './dashboardSettingsWizard.module.scss';
import { WizardViews } from './wizardEnums';
import { StaticImage } from 'gatsby-plugin-image';
import HelpLink from "../common/helpLink/helpLink";
interface Props {
    currentView: WizardViews
}

export const SettingsImage: React.FunctionComponent<Props> = ({
    currentView
}) => {

    const [helpHref, setHelpHref] = useState<string>("https://help.sharemytoolbox.com/knowledge");

    useEffect(() => {
        switch(currentView) {
            case WizardViews.WELCOME:
                setHelpHref("https://help.sharemytoolbox.com/knowledge");
                break;
            case WizardViews.LOCATIONS:
                setHelpHref("https://help.sharemytoolbox.com/knowledge/enterprise-web-portal-and-ios-android-apps#locations");
                break;
            case WizardViews.QUANTITY_ITEMS:
                setHelpHref("https://help.sharemytoolbox.com/knowledge/adding-and-editing-items#qty-items-consumables");
                break;
            case WizardViews.KIT_ITEMS:
                setHelpHref("https://help.sharemytoolbox.com/knowledge/kit-management");
                break;
            case WizardViews.CONSUMABLES:
                setHelpHref("https://help.sharemytoolbox.com/knowledge/consumables");
                break;
            case WizardViews.AUDITS:
                setHelpHref("https://help.sharemytoolbox.com/knowledge/enterprise-web-portal-and-ios-android-apps#audits");
                break;
            case WizardViews.SCAN_TO_ACCEPT:
                setHelpHref("https://help.sharemytoolbox.com/knowledge/web-settings-profile");
                break;
            default:
                setHelpHref("https://help.sharemytoolbox.com/knowledge");
                break;
        }
    }, [currentView])

    return (
       <div className={styles.imageColumn}>
          {currentView !== WizardViews.WELCOME && (
             <div className={styles.imageHelpLink}>
                <HelpLink href={ helpHref } />
             </div>
          )}
          {currentView === WizardViews.WELCOME && (
             <StaticImage
                src={
                   '../../assets/images/settingsWizard/feature-img-welcome.png'
                }
                className={styles.columnImageWelcome}
                formats={['png']}
                alt={'welcome'}
             />
          )}
          {currentView === WizardViews.LOCATIONS && (
             <StaticImage
                src={
                   '../../assets/images/settingsWizard/feature-img-location.png'
                }
                className={styles.columnImage}
                formats={['png']}
                alt={'location'}
             />
          )}
          {currentView === WizardViews.QUANTITY_ITEMS && (
             <StaticImage
                src={'../../assets/images/settingsWizard/feature-img-qty.png'}
                className={styles.columnImage}
                formats={['png']}
                alt={'quantity'}
             />
          )}
          {currentView === WizardViews.KIT_ITEMS && (
             <StaticImage
                src={'../../assets/images/settingsWizard/feature-img-kits.png'}
                className={styles.columnImageSecondary}
                formats={['png']}
                alt={'kits'}
             />
          )}
          {currentView === WizardViews.CONSUMABLES && (
             <StaticImage
                src={
                   '../../assets/images/settingsWizard/feature-img-consumables.png'
                }
                className={styles.columnImageTertiary}
                formats={['png']}
                alt={'consumables'}
             />
          )}
          {currentView === WizardViews.AUDITS && (
             <StaticImage
                src={'../../assets/images/settingsWizard/feature-img-audits.png'}
                className={styles.columnImageQuaternary}
                formats={['png']}
                alt={'audits'}
             />
          )}
          {currentView === WizardViews.SCAN_TO_ACCEPT && (
             <StaticImage
                src={'../../assets/images/settingsWizard/feature-img-scan-to-accept.png'}
                className={styles.columnImageQuinary}
                formats={['png']}
                alt={'audits'}
                width={420}
             />
          )}
          {currentView === WizardViews.COMPLETE_SETUP && (
            <StaticImage
               src={'../../assets/images/settingsWizard/feature-img-final.png'}
               className={styles.columnImageFinal}
               formats={['png']}
               alt={'complete_setup'}
            />
          )}
       </div>
    )
 }