import React, { useEffect, useState } from 'react'
import * as styles from './dashboardSettingsWizard.module.scss'
import { WizardViews } from './wizardEnums'
import { Button } from '../common'
import { Toggle } from '../common/form/fields'
import { Col, Row } from 'react-grid-system'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

interface props {
   handleAdvanceWizard: () => void
   handleBackTrackWizard: () => void
   currentToggleValue: boolean
   handleSettingsToggle: () => void
   currentView?: number
}

export const SettingsContent: React.FunctionComponent<props> = ({
   handleAdvanceWizard,
   handleBackTrackWizard,
   currentToggleValue,
   handleSettingsToggle,
   currentView,
}) => {
   const minScreenWidth = useMediaQuery({ query: '(max-width: 575px)' })
   const [heading, setHeading] = useState<string>('')
   const [content, setContent] = useState<any>(<></>)
   const [toggleLabel, setToggleLabel] = useState<string>()

   useEffect(() => {
      switch (currentView) {
         case WizardViews.LOCATIONS:
            setHeading('Do you want to set up multiple Locations?')
            setContent(
               <>
                  By default ShareMyToolbox tracks who has an Item. Enable
                  Location tracking if you {!minScreenWidth && <br />}
                  want to store materials in multiple warehouses or if you want
                  to track jobsites in {!minScreenWidth && <br />}
                  addition to who has an item. When the wizard finishes please
                  navigate to {!minScreenWidth && <br />}
                  the Location menu to add your locations.
               </>
            )
            setToggleLabel('Location Tracking ')
            break
         case WizardViews.QUANTITY_ITEMS:
            setHeading('Do you want to enable Quantity Items?')
            setContent(
               <>
                  Quantity Items allow you to have a single item record, but to
                  have multiples {!minScreenWidth && <br />}
                  available. Examples include safety belts, hard hats, extension
                  cords etc. {!minScreenWidth && <br />}
                  Generally Quantity Items do not have serial numbers or
                  barcodes.
               </>
            )
            setToggleLabel('Quantity Items')
            break
         case WizardViews.KIT_ITEMS:
            setHeading('Do you want to enable Kit Items?')
            setContent(
               <>
                  Kit Items allow you to setup an individual item that will act
                  as a parent Kit to {!minScreenWidth && <br />}
                  hold other items. After it is created, you can add other items
                  into the parent {!minScreenWidth && <br />}
                  Kit. Whenever you move the Kit, all the items inside it move
                  at the same time. {!minScreenWidth && <br />}
                  Examples of Kits include Knaack boxes, scaffolding packages
                  etc.
               </>
            )
            setToggleLabel('Kit Items')
            break
         case WizardViews.CONSUMABLES:
            setHeading('Do you want to enable Consumables?')
            setContent(
               <>
                  Consumables give you an easy way to set up and send items like
                  tape, caulk, {!minScreenWidth && <br />}
                  saw blades and more. The difference with Consumables is that
                  you don't {!minScreenWidth && <br />}
                  expect them to be returned, unlike other item types.
               </>
            )
            setToggleLabel('Consumables')
            break
         case WizardViews.AUDITS:
            setHeading('Do you want to enable Audits?')
            setContent(
               <>
                  Audits allow you to send a list of items to an employee and
                  have them {!minScreenWidth && <br />}
                  "confirm" that each tool is present and accounted for.
               </>
            )
            setToggleLabel('Audits')
            break
         case WizardViews.SCAN_TO_ACCEPT:
            setHeading('Do you want to require Scan to Accept?')
            setContent(
               <>
                  By default, when an Admin Loans an Item to Employee users, the
                  Items can be {!minScreenWidth && <br />}
                  manually accepted by tapping an Accept button or
                  electronically accepted by {!minScreenWidth && <br />}
                  scanning an asset tag.
                  <br />
                  <br />
                  Scanning an Item for acceptance captures a GPS location at the
                  time of {!minScreenWidth && <br />}
                  scanning. If you would like to force ALL Employees to accept
                  Items with a scan {!minScreenWidth && <br />}
                  so that GPS capture is required, then enable this function
                  option. <br />
                  <br />
                  Please note: <br />
                  Items that do not have a unique barcode will allow manual
                  acceptance. <br />
                  <br />
                  If you want some employees to be required to scan and some to
                  not be required {!minScreenWidth && <br />}
                  then leave this function turned off and update the individual
                  Employee profile {!minScreenWidth && <br />}
                  when setting up the Employee User Connection.
               </>
            )
            setToggleLabel('Scan to Accept Required')
            break
      }
   }, [currentView, minScreenWidth])

   const settingsInnerColStyle = classNames(styles.settingsInnerCol, {
      [styles.settingsInnerColScanToAccept]:
         !minScreenWidth && currentView === WizardViews.SCAN_TO_ACCEPT,
      [styles.settingsInnerColScanToAcceptMinWidth]:
         minScreenWidth && currentView === WizardViews.SCAN_TO_ACCEPT,
   })

   const columnHeaderStyle = classNames(styles.columnHeader, {
      [styles.columnHeaderMinScreenWidth]:
         minScreenWidth && currentView !== WizardViews.SCAN_TO_ACCEPT,
      [styles.columnHeaderMinScreenWidthScanToAccept]:
         minScreenWidth && currentView === WizardViews.SCAN_TO_ACCEPT,
   })

   return (
      <div className={settingsInnerColStyle}>
         <br />
         <h2 className={columnHeaderStyle}>{heading}</h2>
         <p
            className={
               !minScreenWidth
                  ? styles.columnParagraph
                  : styles.columnParagraphMinScreenWidth
            }
         >
            {content}
         </p>
         <div className={styles.settingsToggle}>
            {/* having two toggles stops a sliding effect when moving back and forth. */}
            {currentToggleValue && (
               <Toggle
                  id="SettingsToggle"
                  size="md"
                  isChecked={currentToggleValue}
                  onToggle={handleSettingsToggle}
               />
            )}
            {!currentToggleValue && (
               <Toggle
                  id="SettingsToggle"
                  size="md"
                  isChecked={currentToggleValue}
                  onToggle={handleSettingsToggle}
               />
            )}
            <div className={styles.toggleLabel}>
               {toggleLabel} <br />{' '}
               {currentToggleValue ? 'Enabled' : 'Disabled'}
            </div>
         </div>

         <br />
         <div
            className={
               minScreenWidth
                  ? styles.controlWrapperMinWidth
                  : styles.controlWrapper
            }
         >
            <Row>
               <Col
                  sm={6}
                  style={{
                     marginBottom: '1rem',
                     display: 'flex',
                     justifyContent: minScreenWidth ? 'flex-start' : 'center',
                  }}
               >
                  <Button
                     minWidth="17rem"
                     maxWidth="17rem"
                     variant="tertiary"
                     onClick={handleBackTrackWizard}
                     isReset
                  >
                     Back
                  </Button>
               </Col>

               <Col
                  sm={6}
                  style={{
                     marginBottom: '1rem',
                     display: 'flex',
                     justifyContent: minScreenWidth ? 'flex-start' : 'center',
                  }}
               >
                  <Button
                     minWidth="17rem"
                     maxWidth="17rem"
                     onClick={handleAdvanceWizard}
                     isReset
                  >
                     Next
                  </Button>
               </Col>
            </Row>
         </div>
      </div>
   )
}
