import { StatusValue } from '../../../../types'
import { CountConfig } from './types'

export const COMPANY_TOTALS = [
   'available',
   'consumableAvailable',
   'loaned',
   'pending',
   'unavailable',
]
export const EMPLOYEE_TOTALS = [
   'available',
   'borrowed',
   'consumableAvailable',
   'pending',
]

export enum TypeValues {
   STANDARD = '1',
   QUANTITY = '2',
   KIT = '3',
   CONSUMABLE = '4',
}

export const ITEM_TOTALS: CountConfig = {
   available: {
      color: '#6eb250',
      icon: 'warehouse',
      label: 'Available',
      id: StatusValue.Available,
      restricted: [],
   },
   borrowed: {
      color: '#1A4F90',
      icon: 'warehouse loaned',
      label: 'Borrowed',
      id: StatusValue.Borrowed,
      restricted: [],
   },
   consumableAvailable: {
      color: '#6eb250',
      icon: 'warehouse',
      label: 'Consumables',
      id: StatusValue.ConsumablesAvailable,
      restricted: ['ConsumablesEnabled'],
   },
   loaned: {
      color: '#b21a1f',
      icon: 'warehouse loaned',
      label: 'Loaned',
      id: StatusValue.Loaned,
      restricted: [],
   },
   pending: {
      color: '#e5622a',
      icon: 'warehouse',
      label: 'Pending',
      id: StatusValue.Pending,
      restricted: [],
   },
   unavailable: {
      color: '#96999b',
      icon: 'warehouse',
      label: 'Unavailable',
      id: StatusValue.Unavailable,
      restricted: [],
   },
}
