import React from 'react'
import { useMediaQuery } from 'react-responsive'
import * as styles from './dashboardSettingsWizard.module.scss'
import DownloadAppButton from '../common/downloadAppButton/downloadAppButton'
import { Col, Row } from 'react-grid-system'
import { Button } from '../common'

interface Props {
   handleCompleteSetupWizard: () => void
   handleBackTrackWizard: () => void
}

export const CompleteSettingsView: React.FunctionComponent<Props> = ({
   handleCompleteSetupWizard,
   handleBackTrackWizard,
}) => {
   const minScreenWidth = useMediaQuery({ query: '(max-width: 575px)' })

   return (
      <div className={styles.completeSettingsInnerColumn}>
         <br />
         <h2
            className={
               !minScreenWidth
                  ? styles.columnHeader
                  : styles.columnHeaderMinScreenWidth
            }
            style={{ marginBottom: '1rem' }}
         >
            Congratulations! You're ready to go.
         </h2>
         <p
            className={
               !minScreenWidth
                  ? styles.columnParagraph
                  : styles.columnParagraphMinScreenWidth
            }
         >
            As you get started, know there are many more options and
            customizations to {!minScreenWidth && <br />}
            explore in the Settings menu.
         </p>
         <br />
         <h4
            className={
               !minScreenWidth
                  ? styles.columnHeader
                  : styles.columnSubHeaderMinScreenWidth
            }
            style={{ marginBottom: '1rem' }}
         >
            We'll add sample data to help connect the dots.
         </h4>
         <p
            className={
               !minScreenWidth
                  ? styles.columnParagraph
                  : styles.columnParagraphMinScreenWidth
            }
         >
            We built ShareMyToolbox to be one of the easiest platforms
            available. But <br />
            learning a new system always takes time. Please contact us with
            questions as needed. {!minScreenWidth && <br />}
            Online meetings and phone support are available to help you.
         </p>
         <br />
         <h4
            className={
               !minScreenWidth
                  ? styles.columnHeader
                  : styles.columnSubHeaderMinScreenWidth
            }
            style={{ marginBottom: '1rem' }}
         >
            Don't forget to download the mobile app.
         </h4>
         <p
            className={
               !minScreenWidth
                  ? styles.columnParagraph
                  : styles.columnParagraphMinScreenWidth
            }
         >
            Get the ShareMyToolbox app for Apple or Android devices. You and
            your field {!minScreenWidth && <br />}
            teams will have easy access to the power of ShareMyToolbox right on
            a phone {!minScreenWidth && <br />}
            or tablet.
         </p>
         <br />
         <div
            className={
               !minScreenWidth
                  ? styles.downloadWrapper
                  : styles.downloadWrapperMinScreen
            }
         >
            <DownloadAppButton width={!minScreenWidth ? '3rem' : '2rem'} />
            <h3
               className={
                  !minScreenWidth
                     ? styles.columnHeader
                     : styles.columnSubHeaderMinScreenWidth
               }
               style={{
                  marginBottom: '0',
                  marginTop: '0.25rem',
                  marginLeft: minScreenWidth && '1rem',
               }}
            >
               Download the App
            </h3>
         </div>
         <div
            className={
               !minScreenWidth
                  ? styles.controlWrapper
                  : styles.controlWrapperCompleteMinWidth
            }
         >
            <Row>
               <Col
                  sm={6}
                  style={{
                     marginBottom: '1rem',
                     display: 'flex',
                     justifyContent: minScreenWidth ? 'flex-start' : 'center',
                  }}
               >
                  <Button
                     minWidth="17rem"
                     maxWidth="17rem"
                     variant="tertiary"
                     onClick={handleBackTrackWizard}
                     isReset
                  >
                     Back
                  </Button>
               </Col>

               <Col
                  sm={6}
                  style={{
                     marginBottom: '1rem',
                     display: 'flex',
                     justifyContent: minScreenWidth ? 'flex-start' : 'center',
                  }}
               >
                  <Button
                     minWidth="17rem"
                     maxWidth="17rem"
                     onClick={handleCompleteSetupWizard}
                     isReset
                  >
                     Complete Setup
                  </Button>
               </Col>
            </Row>
         </div>
      </div>
   )
}
