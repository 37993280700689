import React from 'react'
import logo from '../../../assets/images/smt_favicon.png';

interface Props {
   width?: string
}

const DownloadAppButton: React.FunctionComponent<Props> = ({ width = '3' }) => {
   return (
      <>
         <a href="https://sharemytoolbox.app.link/install" target="_blank" style={{textDecoration: 'none', border: 'none'}}>
            <img style={{ width, borderRadius: '15%' }} src={logo} alt="ShareMyToolbox" />
         </a>
      </>
   )
}

export default DownloadAppButton
