export enum WizardViews {
   WELCOME = 0,
   LOCATIONS = 1,
   QUANTITY_ITEMS = 2,
   KIT_ITEMS = 3,
   CONSUMABLES = 4,
   AUDITS = 5,
   SCAN_TO_ACCEPT = 6,
   COMPLETE_SETUP = 7,
   APPLY_SETUP = 8,
}
