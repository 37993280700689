// extracted by mini-css-extract-plugin
export var columnHeader = "dashboardSettingsWizard-module--columnHeader--TPUUq";
export var columnHeaderMinScreenWidth = "dashboardSettingsWizard-module--columnHeaderMinScreenWidth--ieH7G";
export var columnHeaderMinScreenWidthScanToAccept = "dashboardSettingsWizard-module--columnHeaderMinScreenWidthScanToAccept--4LLhV";
export var columnImage = "dashboardSettingsWizard-module--columnImage--BOU0y";
export var columnImageFinal = "dashboardSettingsWizard-module--columnImageFinal--GA615";
export var columnImageQuaternary = "dashboardSettingsWizard-module--columnImageQuaternary--wXG7E";
export var columnImageQuinary = "dashboardSettingsWizard-module--columnImageQuinary--cuxoO";
export var columnImageSecondary = "dashboardSettingsWizard-module--columnImageSecondary--Quxg3";
export var columnImageTertiary = "dashboardSettingsWizard-module--columnImageTertiary--kJC+P";
export var columnImageWelcome = "dashboardSettingsWizard-module--columnImageWelcome--t0MQ9";
export var columnParagraph = "dashboardSettingsWizard-module--columnParagraph--Q-YLV";
export var columnParagraphMinScreenWidth = "dashboardSettingsWizard-module--columnParagraphMinScreenWidth--Tv5BC";
export var columnSubHeaderMinScreenWidth = "dashboardSettingsWizard-module--columnSubHeaderMinScreenWidth--kumOp";
export var completeSettingsInnerColumn = "dashboardSettingsWizard-module--completeSettingsInnerColumn--xucWw";
export var controlWrapper = "dashboardSettingsWizard-module--controlWrapper--jt99q";
export var controlWrapperCompleteMinWidth = "dashboardSettingsWizard-module--controlWrapperCompleteMinWidth--b0UG4";
export var controlWrapperMinWidth = "dashboardSettingsWizard-module--controlWrapperMinWidth--LhrB+";
export var controlWrapperWelcomeMinWidth = "dashboardSettingsWizard-module--controlWrapperWelcomeMinWidth--CGp7e";
export var downloadWrapper = "dashboardSettingsWizard-module--downloadWrapper---CjKg";
export var downloadWrapperMinScreen = "dashboardSettingsWizard-module--downloadWrapperMinScreen--Y2tH9";
export var imageColumn = "dashboardSettingsWizard-module--imageColumn--Fym9D";
export var imageHelpLink = "dashboardSettingsWizard-module--imageHelpLink--q3sEC";
export var loadingInner = "dashboardSettingsWizard-module--loadingInner--ChHXM";
export var loadingWrapper = "dashboardSettingsWizard-module--loadingWrapper--2-Y+D";
export var progressIndicatorWrapperCenter = "dashboardSettingsWizard-module--progressIndicatorWrapperCenter--CPPxx";
export var progressIndicatorWrapperLeft = "dashboardSettingsWizard-module--progressIndicatorWrapperLeft--nntXe";
export var settingsInnerCol = "dashboardSettingsWizard-module--settingsInnerCol--tSvF-";
export var settingsInnerColScanToAccept = "dashboardSettingsWizard-module--settingsInnerColScanToAccept--1t+Vg";
export var settingsInnerColScanToAcceptMinWidth = "dashboardSettingsWizard-module--settingsInnerColScanToAcceptMinWidth--4MqUO";
export var settingsToggle = "dashboardSettingsWizard-module--settingsToggle--gWQOM";
export var toggleLabel = "dashboardSettingsWizard-module--toggleLabel--N9ID+";
export var welcomeHelp = "dashboardSettingsWizard-module--welcomeHelp--kXZZy";
export var welcomeInnerCol = "dashboardSettingsWizard-module--welcomeInnerCol--LEDF7";
export var wizardWrapper = "dashboardSettingsWizard-module--wizardWrapper--AVdl+";