import React from 'react'

import { LayoutLoggedIn } from '../components/views/layout'
import Dashboard from '../components/views/dashboard/dashboard'

export default function DashboardPage() {
   return (
      <LayoutLoggedIn>
         <Dashboard />
      </LayoutLoggedIn>
   )
}
