import React from 'react'

import * as styles from './welcomeWidget.module.scss'

const WelcomeWidget = () => (
   <div className={styles.wrapper}>
      <p>
         <strong>Thank you for using ShareMyToolbox.</strong>
      </p>
      <p>Get started by:</p>
      <ol>
         <li>Click on Help and follow the Getting Started Tutorials</li>
         <li>
            Click on Settings and review the optional functions you can turn on
            such as:
            <ol type="a">
               <li>Location Tracking</li>
               <li>Audit</li>
               <li>Quantity Items</li>
               <li>Kits</li>
               <li>Consumables</li>
            </ol>
         </li>
      </ol>
   </div>
)

export default WelcomeWidget
