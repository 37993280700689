import React from 'react'
import * as styles from './dashboardSettingsWizard.module.scss'
import { Button } from '../common'
import { useMediaQuery } from 'react-responsive'
import HelpLink from '../common/helpLink/helpLink'

interface props {
   handleAdvanceWizard: () => void
}

export const WelcomeView: React.FunctionComponent<props> = ({
   handleAdvanceWizard,
}) => {
   const minScreenWidth = useMediaQuery({ query: '(max-width: 575px)' })
   return (
      <div className={styles.welcomeInnerCol}>
         <h2
            className={styles.columnHeader}
            style={{ fontSize: minScreenWidth && '1.5rem' }}
         >
            Welcome to your ShareMyToolbox <br />
            Enterprise Portal!
         </h2>
         <p
            className={
               !minScreenWidth
                  ? styles.columnParagraph
                  : styles.columnParagraphMinScreenWidth
            }
         >
            Let's walk through 5 quick decisions to get started! Don't worry,
            after completing this wizard you can change any of your decisions
            under the Settings menu.
         </p>
         <br />
         <div className={styles.welcomeHelp}>
            <HelpLink href={'https://help.sharemytoolbox.com/knowledge'} />
            <h3
               style={{
                  margin: '0 0 0 1rem',
                  fontWeight: '900',
               }}
            >
               Need help?
            </h3>
         </div>
         <p
            className={
               !minScreenWidth
                  ? styles.columnParagraph
                  : styles.columnParagraphMinScreenWidth
            }
         >
            If at any time you need help, just tap the Help icon to learn more
            about a {!minScreenWidth && <br />} feature with support articles
            and tutorial videos, or to contact our team.
         </p>
         <br />
         <h3
            style={{
               fontWeight: '900',
            }}
         >
            Let's get started!
         </h3>
         <br />
         <div
            className={
               minScreenWidth ? styles.controlWrapperWelcomeMinWidth : ''
            }
         >
            <Button
               minWidth="15rem"
               maxWidth="15rem"
               marginLeft="0.5rem"
               onClick={handleAdvanceWizard}
            >
               Next
            </Button>
         </div>
      </div>
   )
}
